@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;
}

@media screen and (max-width: 800px) {
  html,
  body,
  #root,
  #main {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;
  }
}

body {
  background: #f0f0f0;
  font-family: 'Inter', sans-serif;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  align-items: center;
  position: fixed;
}

header p {
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 1.2s ease-out;
}

header p.hidden {
  opacity: 0;
}

section {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.title{
  font-size:20px;
  padding:0;
  margin:0
}

.title2{
  font-size:16px;
  padding:0px;
  margin:0
}

.uiText {
  top: 0%;
  position: absolute;
  width: 100%;
}

.uiTextInt{
  margin-left:150px;
  margin-top:26px
}

@media screen and (max-width: 800px) {
  .title{
    font-size:18px;
    padding:0;
    margin:0
  }
  
  .title2{
    font-size:14px;
    padding:0px;
    margin:0
  }
  
  .uiText {
    top: 70px;
    position: absolute;
    width: 100%;
  }
  
  .uiTextInt{
    margin-left:40px;
    margin-top:14px
  }
}

.uiSection {
  bottom: 10%;
  position: absolute;
  width: 100%;
}

.uiSection.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.uiSection h1 {
  font-weight: 900;
  font-size: 15rem;
  letter-spacing: -6px;
  font-style: italic;
  width: 30%;
  line-height: 10rem;
  font-family: 'Nunito Sans', sans-serif;
}

.uiContent {
  position: relative;
  top: -25%;
}

.uiContent p {
  width: 350px;
  margin-bottom: 48px;
  line-height: 1.5rem;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

button {
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  color: #333;
  font-weight: bold;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 20px !important;
  box-shadow: 0 0 0 0.09px black;
}

button:hover {
  transform: scale(1.04);
  color: #fff;
  background-color: #333;
}

/* CUSTOMIZER */

.customizer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.customizer span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.customizer span svg {
  width: 24px;
  padding-right: 5px;
}

.controls {
  position: absolute;
  right: 20px;
  top: 100px;
  width: 30%;
  z-index: 1000;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 20px;
  border-radius: 5px;
  transition: border, color 1s ease;
  min-height: 100px;
  background-color: rgba(0, 0, 0, 0.4);
}

.controls p {
  font-size: 14px;
  padding-bottom: 0px !important;
  font-weight: normal;
  color: #fff;
}

.controls-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .controls {
    position: none;
    width: 90%;
    margin: 0 auto !important;
    margin-top: 70%;
    color: #00000056;
    font-size: 0.6rem;
    padding: 10px 20px;
    border-radius: 5px;
    transition: border, color 1s ease;
    min-height: 100px;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.rotation {
  width: 60%;
}

.share {
  bottom: 40px;
  position: absolute;
  right: 40px;
}

.zoom {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: border, color 1s ease;
}

.zoom:hover {
  color: black;
}

/* PICKER */

.picker {
  position: absolute !important;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  width: 120px !important;
  height: 120px !important;
}

.color-options {
  display: flex;
  gap: 10px;
}

.color {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid white;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.color:hover {
  transform: scale(1.2);
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .uiSection {
  }

  .uiSection h1 {
    font-size: 8rem;
    letter-spacing: -6px;
    line-height: 6rem;
  }

  .color-options {
    gap: 4px;
  }

  .color {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid white;
    transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
  }

  .uiContent {
    top: -10%;
    left: 0;
  }
}

.decals p {
  font-family: 'Inter var';
  font-size: 0.7rem;
  padding-bottom: 15px;
}

.decals img {
  width: 44px;
  transition: all 0.2s ease-in-out;
}

.decals img:hover {
  filter: none;
  transform: scale(1.2);
  cursor: pointer;
}

.decals--position {
  position: absolute;
  left: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 10px;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: border 1s ease;
}

.decals--position:hover {
  color: black;
  border: 1px solid #00000056;
}

.upload {
  margin-top: -5px;
  cursor: pointer;
}

.upload:hover {
  color: white;
}

.decals--container {
  display: flex;
  gap: 20px;
}

.uiButton1 {
  position: absolute;
  bottom: 5%;
  left: 25%;
}

.uiButton2 {
  position: absolute;
  bottom: 5%;
  right: 25%;
}

@media screen and (max-width: 1600px) {
  .uiButton1 {
    left: 15%;
  }
  .uiButton2 {
    right: 15%;
  }
}

@media screen and (max-width: 1200px) {
  .uiButton1 {
    left: 10%;
  }
  .uiButton2 {
    right: 10%;
  }
}

@media screen and (max-width: 1000px) {
  .uiButton1 {
    left: 5%;
  }
  .uiButton2 {
    right: 5%;
  }
}

@media screen and (max-width: 900px) {
  .uiButton1 {
    position: relative;
    margin-left: 20px;
    left: 0;
  }
  .uiButton2 {
    position: relative;
    margin-left: 20px;
    margin-top: 20px;
    clear: both;
    left: 0;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #999;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logoPreloader {
  width: 60px;
  position: absolute;
}
